import { Injectable } from '@angular/core';
import { configuration } from './configuration';
import { environment } from './../environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Post } from './Post';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

	config = configuration;
	//apiUrl = environment.emailUrl;
	emailUrl = 'https://www.blueramp.in/contact.php';
	apiUrl = 'api/posts';
	apiUrlRead = "https://www.blueramp.in/apis";
	
  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
	getConfig() {
	  return this.config;
  }

    getProjectbyWorklink( worklink: string ) {
		
			let work: any;

		for (var j=0; j < this.config.projects.length; j++ ) {
             if ( this.config.projects[j].worklink === worklink ) {
				 work = this.config.projects[j];
				}
		}
		
	  return work;
  }
  
    getServiceByServicelink( servicelink: string ) {
			let service: any;

		for (var i=0; i < this.config.service.length; i++ ) {
             if ( this.config.service[i].servicelink === servicelink ) {
				 service = this.config.service[i];
				}
		
		}
		
	  return service;
  }
  
    /*  getPostByPostlink( postlink: string ) {
		
				let post: any;
		for (var i=0; i < this.config.blog.posts.length; i++ ) {
             if ( this.config.blog.posts[i].postlink === postlink ) {
				 post = this.config.blog.posts[i];
				}
				
		
		}
		
	  return post;
	}*/
	

	sendMessage(formData: NgForm): Observable<any> {
		return this.http.post<any>(this.emailUrl, formData, httpOptions).pipe(
			tap(
			//	 message =>console.log(message)
			),
			catchError(this.handleError('Sending Message', []))
		);
	}
	
	getPosts(): Observable <Post[]> {
		
		return this.http.get<any>(`${this.apiUrlRead}/read.php`).pipe(
		tap(),
		catchError(this.handleError('Get Posts', []))
	);
	}
	
	getAspires(): Observable <Post[]> {
		
		return this.http.get<any>(`${this.apiUrlRead}/read-aspires.php`).pipe(
		tap(),
		catchError(this.handleError('Get Aspires', []))
	);
	}
	
	
	getPostByID(id: number) {
    return this.http.get<any>(`${this.apiUrlRead}/post.php?id=${id}`).pipe(
      tap(),
      catchError(this.handleError('Get Post by ID', []))
     );
    }
	
	getAspireByID(id: number) {
    return this.http.get<any>(`${this.apiUrlRead}/aspire.php?id=${id}`).pipe(
      tap(),
      catchError(this.handleError('Get Post by ID', []))
     );
    }
  
    updatePost(formData: NgForm): Observable<Post[]> {
    return this.http.put<any>(`${this.apiUrlRead}/update.php`, formData, httpOptions).pipe(
      tap(
        post => console.log(post)
      ),
      catchError(this.handleError('Update Post', []))
    );
  }
    updateAspire(formData: NgForm): Observable<Post[]> {
    return this.http.put<any>(`${this.apiUrlRead}/update-aspires.php`, formData, httpOptions).pipe(
      tap(
        post => console.log(post)
      ),
      catchError(this.handleError('Update Post', []))
    );
  }
  
    addPost(formData: NgForm): Observable<Post[]> {
    return this.http.post<any>(`${this.apiUrlRead}/create.php`, formData, httpOptions).pipe(
      tap(
        post => console.log(post)
      ),
      catchError(this.handleError('Add New Post', []))
     );
    }
	
	addAspire(formData: NgForm): Observable<Post[]> {
    return this.http.post<any>(`${this.apiUrlRead}/create-aspires.php`, formData, httpOptions).pipe(
      tap(
        post => console.log(post)
      ),
      catchError(this.handleError('Add New Aspire', []))
     );
    }



}
