import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
   isloggedIn: boolean;

  constructor(@Inject(WINDOW) private window: Window,  private fb: FormBuilder, 
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router 
    ) { }

  ngOnInit() {
	this.isloggedIn = this.auth.isloggedIn();
    this.loginForm = this.fb.group({
      'email' : [null, [Validators.required, Validators.email]],
      'password' : [null, Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  
  
  
  
  }

  login (formData: NgForm) {
    return this.auth.login(formData).subscribe(
      (user) => {
        console.log(user);
		this.window.location.reload();
        this.router.navigate([this.returnUrl]);
      });
    
  }

}
