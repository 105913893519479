import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SeoService } from '../seo.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contact:any = {};
  contactForm: FormGroup;
  sending: boolean;

  constructor(private config: ConfigService, private fb: FormBuilder, private router: Router, private seo: SeoService ) { }

  ngOnInit() {
    this.contact = this.getContact();
    this.contactForm = this.fb.group({
      'name': [null, Validators.required],
      'email': [null, [Validators.required, Validators.email]],
      'subject': [null, Validators.required],
      'message': [null, Validators.required]

    });
	this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.contact.title +' | Blue Ramp Management',
			  this.contact.subtitle,
			  'background1.jpg');

    this.sending = false;

  }

  sendMessage(formData: NgForm) {
    this.sending = true;
    console.log(formData);
    this.config.sendMessage(formData).subscribe(
      data => console.log(data)
    );

    
    setTimeout(() => {
      this.sending = false;

    }, 5000);
  }



  getContact() {
    return this.config.getConfig().contact;
  }
}