import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
	
	services:any = {};

  constructor( private config: ConfigService, private seo: SeoService) { }

  ngOnInit() {
	  this.services = this.getServices();
	  this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.services.title +' | Blue Ramp Management',
			  this.services.subtitle,
			  'background1.jpg');
  }
getServices() {
	return this.config.getConfig().services;
}
}
