import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { RouterLinkActive } from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  isloggedIn: boolean;
  status: boolean = false;
  clickEvent(){
    this.status = !this.status;       
  }

  constructor( private location: Location,
    private auth: AuthenticationService) { }

  ngOnInit() {
    this.isloggedIn = this.auth.isloggedIn();

  }


  logout() {
    this.auth.logout();
  }

}
