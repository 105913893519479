import { Component, OnInit, Inject  } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute  } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { Title, Meta }     from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

  // declare ga as a function to set and sent the events
 declare let ga: Function;
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
	
	title = 'app';
	
    constructor(@Inject(WINDOW) private window: Window, private router: Router, private titleService: Title, private metaService: Meta, private activatedRoute: ActivatedRoute ) {
		// subscribe to router events and send page views to Google Analytics
			this.router.events.subscribe(event => {

			  if (event instanceof NavigationEnd) {
				ga('set', 'page', event.urlAfterRedirects);
				ga('send', 'pageview');

			  }

			});
		}

    ngOnInit() {
		
		this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {

                return;
            }
            this.window.scrollTo(0, 0)
        });
		
		
	  this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          console.log(data);
          this.titleService.setTitle(data.title);
		  this.metaService.updateTag( { name: 'description', content: data.meta }  );
		  });
		  
      })
 
  }
 
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
		

    
	

}
