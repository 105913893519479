import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';


@Injectable({
  providedIn: 'root'
})
export class RouteguardService implements CanActivate{

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,  private router: Router) { }
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.localStorage.getItem('currentUser')){
      //logged in
      return true;
    }
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
