import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config.service';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  service:any = {};
  excerpt: string;

  
  
  constructor( private route: ActivatedRoute, private config: ConfigService, private titleService: Title,  private metaService: Meta,  private seo: SeoService ) { }
  

  ngOnInit() {

			let servicelink = this.route.snapshot.paramMap.get('servicelink');
			this.service = this.getServiceByServicelink(servicelink);
			this.titleService.setTitle( this.service.title + ' | Blue Ramp Management' );
			this.excerpt = String(this.service.subtitle).replace(/<[^>]+>/gm, '');
			this.excerpt = this.excerpt.substr(0, 170); 
			this.metaService.updateTag( { name: 'description', content: this.excerpt+'...' }  ); 
			this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.service.title +' | Blue Ramp Management',
			  this.excerpt+'...',
			  'background1.jpg');
			
  }


getServiceByServicelink ( servicelink: string ) {
	return this.config.getServiceByServicelink(servicelink);
}

}


