import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { ConfigService } from '../config.service';
import { Post } from '../Post';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

   post: Post;
   

  
  
  constructor( private route: ActivatedRoute, private config: ConfigService, private location: Location, private titleService: Title, private metaService: Meta, private seo: SeoService  ) { }
  

  ngOnInit() {

			/*let postlink = this.route.snapshot.paramMap.get('postlink');
			this.post = this.getPostByPostlink(postlink);*/
			//let id = this.route.snapshot.paramMap.get('id');
			const id = +this.route.snapshot.paramMap.get('id');
			//this.post = this.getPostByID(id);
			this.getPostByID(id);
			
			
  }

/*
getPostByPostlink ( postlink: string ) {
	return this.config.getPostByPostlink(postlink);
}*/

  getPostByID(id: number) {
    return this.config.getPostByID(id).subscribe(
      post => {
		  this.post = post;
		  this.titleService.setTitle( this.post.title + ' | Blue Ramp Management' );
		  this.metaService.updateTag( { name: 'description', content: this.post.excerpt }  ); 
		  this.seo.setSocialMediaTags(
			  'https://www.blueramp.in/posts/'+this.post.id, 
			  this.post.title,
			  this.post.excerpt,
			  this.post.img +'.jpg');
		  }
    );
	
  }
  
    getBack() {
    this.location.back();
  }
}

