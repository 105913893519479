import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../config.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-post-edit',
  templateUrl: './post-edit.component.html',
  styleUrls: ['./post-edit.component.css']
})
export class PostEditComponent implements OnInit {
	
	postEditForm: FormGroup;
	id : number; 

    constructor( private fb: FormBuilder, 
    private auth: AuthenticationService,
	private config: ConfigService,
    private route: ActivatedRoute,
	private location: Location,
    private router: Router 
    ) { }

  ngOnInit() {
	  

    this.postEditForm = this.fb.group({
      'id' : [null, Validators.required],
      'title' : [null, Validators.required],
	  'excerpt' : [null, Validators.required],
	  'img' : [null, Validators.required],
	  'postlink' : [null, Validators.required],
	  'detail' : [null, Validators.required],
    });
	this.id = this.route.snapshot.params['id'] || null;
		
	if (this.id) {
	this.getPostByID( this.id );
	}
  }
  
  getPostByID(id: number) {
	  this.config.getPostByID(id).subscribe(
	  post => {
		  this.postEditForm.setValue({
			 id: post.id,
			 title: post.title,
			 excerpt: post.excerpt,
			 detail: post.detail,
			 postlink: post.postlink,
			 img: post.img
			  
		  });
	  }
		  
		  
		  );
  }
  
    updatePost(formData: NgForm) {
    this.config.updatePost(formData).subscribe(
      () => this.getBack()
    );
  }
  
    getBack() {
    this.location.back();
  }
  

}
