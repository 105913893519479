import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

	@Input() pager;
	@Output() setPage: EventEmitter<any> = new EventEmitter<any>() ;
	


  constructor(@Inject(WINDOW) private window: Window, ) { }

  ngOnInit() {
  }

setCurrentPage(pageNumber: number) {
	

	
	this.setPage.emit(pageNumber);
	this.window.scrollTo(0,0);
}

}
