import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../config.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-aspire-create',
  templateUrl: './aspire-create.component.html',
  styleUrls: ['./aspire-create.component.css']
})
export class AspireCreateComponent implements OnInit {

	postCreateForm: FormGroup;


    constructor( private fb: FormBuilder, 
    private auth: AuthenticationService,
	private config: ConfigService,
    private route: ActivatedRoute,
	private location: Location,
    private router: Router 
    ) { }

  ngOnInit() {
	  

    this.postCreateForm = this.fb.group({

      'title' : [null, Validators.required],
	  'excerpt' : [null, Validators.required],
	  'img' : [null, Validators.required],
	  'postlink' : [null, Validators.required],
	  'detail' : [null, Validators.required],
    });

  }
  

  
  addPost(formData: NgForm) {
    this.config.addAspire(formData).subscribe(
      post => this.router.navigate([`aspires/${post['id']}`])
    );
  }
  
    getBack() {
    this.location.back();
  }
  

}
