import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
	
	portfolio:any = {};

  constructor( private config: ConfigService, private seo: SeoService) { }

  ngOnInit() {
	  this.portfolio = this.getPortfolio();
	  this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.portfolio.title +' | Blue Ramp Management',
			  this.portfolio.subtitle,
			  'background1.jpg');
  }


getPortfolio() {
	return this.config.getConfig().portfolio;
}
}
