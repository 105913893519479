import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
	

  about:any = {};

  
  
  constructor( private config: ConfigService, private seo: SeoService) { }
  

  ngOnInit() {
	  	  this.about = this.getAbout();
		  this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.about.title +' | Blue Ramp Management',
			  this.about.subtitle,
			  'background1.jpg');

  }


getAbout() {
	return this.config.getConfig().about;
}
}
