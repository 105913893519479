import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.css']
})
export class NumbersComponent implements OnInit {
	
	numbers:any = {};

  constructor( private config: ConfigService) { }

  ngOnInit() {
	  this.numbers = this.getNumbers();
  }

getNumbers() {
	return this.config.getConfig().numbers;
}
}
