import { Injectable, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

   apiUrl = 'api/users';



  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
   
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
   
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
   
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  constructor(@Inject(WINDOW) private window: Window, @Inject(LOCAL_STORAGE) private localStorage: any,  private http: HttpClient, private router: Router) { }

  login(formData: NgForm) {
    return this.http.post<any>( `${this.apiUrl}/login`, formData).pipe(
      tap(user => {
        if (user && user.token) {
          this.localStorage.setItem('currentUser', JSON.stringify(user));
		  
        } 
		else {
			this.window.location.reload();
		}

      }),
      catchError(this.handleError('login', []))
    );
  }

  logout() {
    if (this.localStorage.getItem('currentUser')) {
      this.localStorage.removeItem('currentUser');
	  this.window.location.reload();
     // this.router.navigate(['/']);
    }
  }

  isloggedIn() {
    if (this.localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }

  getUser() {
    if (this.isloggedIn) {
      return JSON.parse(this.localStorage.getItem('currentUser'));
    }
  }





}
