import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ServicesComponent } from './services/services.component';
import { LoginComponent } from './login/login.component';
import { TeamComponent } from './team/team.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { BluerampComponent } from './blueramp/blueramp.component';
import { ProjectComponent } from './project/project.component';
import { ServiceComponent } from './service/service.component';
import { ArticleComponent } from './article/article.component';
import { AspireComponent } from './aspire/aspire.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CareersComponent } from './careers/careers.component';
import { RouteguardService } from './routeguard.service';
import { OpeningComponent } from './opening/opening.component';
import { PostEditComponent } from './post-edit/post-edit.component';
import { PostCreateComponent } from './post-create/post-create.component';
import { HrAspireComponent } from './hr-aspire/hr-aspire.component';
import { AspireCreateComponent } from './aspire-create/aspire-create.component';
import { AspireEditComponent } from './aspire-edit/aspire-edit.component';


const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full', 
    data: {title: 'Blue Ramp Management', meta: "We provide end-to-end solutions right from ideation to marketing to execution for Event IPs, Festivals, Conferences, Corporate Events, Product Launches, Sports Facility Development & Marathons"} },
  
  { path: '', component: HeaderComponent, 
    data: {title: 'Blue Ramp Management', meta: "We provide end-to-end solutions right from ideation to marketing to execution for Event IPs, Festivals, Conferences, Corporate Events, Product Launches, Sports Facility Development & Marathons"} },
  
  { path: 'about', component: AboutComponent,
    data: {title: 'About | Blue Ramp Management', meta: "Since 1999, we have provided integrated services to our clients through three firms - Twiddle Designography, Fairsense Management & Unbounce Marketing. “Blue Ramp Management Pvt Ltd” is a coming together of these three firms into one to provide more seamless solutions to our clients. Over the years we have created our own event IPs and helped the creation and growth of our clients IPs."} },
  
  { path: 'login', component: LoginComponent,
    data: {title: 'Login | Blue Ramp Management', meta: "Login Page | Blue Ramp Management"} },
  
  { path: 'portfolio', component: PortfolioComponent,
    data: {title: 'Works | Blue Ramp Management', meta: "Over the years, we have worked with clients across various projects. From initial challenges to remarkable results, these are the stories behind our clients' successes."} },
  
  { path: 'services', component: ServicesComponent,
    data: {title: 'Services | Blue Ramp Management', meta: "Our services include Brand & Festival Creation, Scaling Up, Sports Facility Development, Sports Celebrations, Marathons, Corporate Activations, Exhibitions, Activity Club Management, Event Marketing, Community Events, Digital Marketing, Social Media Management, Website Development, User Experience Design and Design Services."} },
  
  { path: 'careers', component: CareersComponent,
    data: {title: 'Careers | Blue Ramp Management', meta: "Be part of our vibrant, dynamic environment. You will be expected to conquer professional challenges, but we'll also find time for fun. We prize out-of-the box thinking and reward excellence."} },
  
  { path: 'testi', component: TestimonialComponent,
    data: {title: 'Testimonials | Blue Ramp Management', meta: "What are clients are saying."} },
  
  { path: 'team', component: TeamComponent,
    data: {title: 'Team | Blue Ramp Management', meta: "Our team is your team. When your mission is to be better, faster and smarter, you need the best people driving your vision forward. You need people who can create focused marketing strategies that align with business goals, who can infuse their creativity into groundbreaking campaigns, and who can analyze data to optimize every tactic along the way. You need Blue Ramp. Get to know your team below."} },
  
  { path: 'blog', component: BlogComponent,
    data: {title: 'Recent Works | Blue Ramp Management', meta: "Our Recent works and updates."} },
	
  { path: 'hr-aspire', component: HrAspireComponent,
    data: {title: 'HR Aspire | Blue Ramp Management', meta: "Resources for HR people. Management, recruiting, leadership and career tips. Learn business tips for your professional success."} },
 
  { path: 'contact', component: BluerampComponent,
    data: {title: 'Contact | Blue Ramp Management', meta: "Whether you have a question or need assistance, you may contact us anytime…"} },
 
  { path: 'opening/:link', component: OpeningComponent },
 
  { path: 'service/:servicelink', component: ServiceComponent },

  { path: 'blog/:postlink', component: ArticleComponent },

  { path: 'posts/:id', component: ArticleComponent },

  { path: 'aspires/:id', component: AspireComponent },

  { path: 'project/:worklink', component: ProjectComponent },

  { path: 'post-create', component: PostCreateComponent, canActivate: [RouteguardService] },

  { path: 'post-edit/:id', component: PostEditComponent, canActivate: [RouteguardService] },

  { path: 'aspire-create', component: AspireCreateComponent, canActivate: [RouteguardService] },

  { path: 'aspire-edit/:id', component: AspireEditComponent, canActivate: [RouteguardService] },

  { path: '404', component: NotfoundComponent, 
    data: {title: 'Page Not Found | Blue Ramp Management', meta: "You have found a page that does not curently exist."} },

  { path: '**', redirectTo: '/404'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
