import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../config.service';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  project:any = {};
  excerpt: string;

  
  
  constructor( private route: ActivatedRoute, private config: ConfigService, private titleService: Title,  private metaService: Meta , private seo: SeoService ) { }
  

  ngOnInit() {

			let worklink = this.route.snapshot.paramMap.get('worklink');
			this.project = this.getProjectbyWorklink(worklink);
			this.titleService.setTitle( this.project.title + ' | Blue Ramp Management' );
			this.excerpt = String(this.project.subtitle).replace(/<[^>]+>/gm, '');
			this.excerpt = this.excerpt.substr(0, 170); 
			this.metaService.updateTag( { name: 'description', content: this.excerpt+'...' }  ); 
			this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.project.title +' | Blue Ramp Management',
			  this.excerpt+'...',
			  this.project.workimage +'.jpg');
  }


getProjectbyWorklink ( worklink: string ) {
	return this.config.getProjectbyWorklink(worklink);
}



}
