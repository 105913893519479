import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

	careers:any = {};
	excerpt: string;

  constructor( private config: ConfigService, private seo: SeoService) { }

  ngOnInit() {
	  this.careers = this.getCareers();
	  this.excerpt = String(this.careers.subtitle).replace(/<[^>]+>/gm, '');
	  this.excerpt = this.excerpt.substr(0, 170); 
	  this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.careers.title +' | Blue Ramp Management',
			  this.excerpt+'...',
			  'background1.jpg');
  }
getCareers() {
	return this.config.getConfig().careers;
}
}
