import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { NgxMasonryOptions } from 'ngx-masonry';
import { SeoService } from '../seo.service';


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  team: any = {};
  updateLayout: boolean;
  memberCount: number;


  constructor(private config: ConfigService, private seo: SeoService) { }

  ngOnInit() {

    this.team = this.getTeam();
    this.updateLayout = true;
    this.memberCount = 0;
	this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.team.title +' | Blue Ramp Management',
			  this.team.subtitle,
			  'background1.jpg');




  }

  getTeam() {
    return this.config.getConfig().team;
  }

  public layoutUpdater() {
	  this.memberCount++;
	  if (this.memberCount < 7) {
      this.updateLayout = !this.updateLayout;
      console.log(this.updateLayout);
	  }
    

  }
	public myOptions: NgxMasonryOptions = {
		transitionDuration: '0s',
		horizontalOrder: true

	}


}
