import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { PortfolioComponent } from './portfolio/portfolio.component';

import { ServicesComponent } from './services/services.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NumbersComponent } from './numbers/numbers.component';

import { TestimonialComponent } from './testimonial/testimonial.component';
import { TeamComponent } from './team/team.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { ConfigService } from './config.service';
import { PagerService } from './pager.service';
import { ProjectComponent } from './project/project.component';
import { ServiceComponent } from './service/service.component';
import { ClientsComponent } from './clients/clients.component';
import { ArticleComponent } from './article/article.component';
import { NotfoundComponent } from './notfound/notfound.component';

import { PaginationComponent } from './pagination/pagination.component';
import { LoginComponent } from './login/login.component';

import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './in-memory-data.service';
import { CareersComponent } from './careers/careers.component';
import { OpeningComponent } from './opening/opening.component';
import { PostEditComponent } from './post-edit/post-edit.component';
import { PostCreateComponent } from './post-create/post-create.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { NgxMasonryModule } from 'ngx-masonry';
import {GoogleAnalyticsService} from "./google-analytics.service";
import { AppModule } from './app.module';

@NgModule({
  imports: [
    
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMasonryModule,
    // using specific options with ValueProvider and passing HttpClient
    MarkdownModule.forRoot({
      loader: HttpClientModule, // optional, only if you use [src] attribute
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    HttpClientInMemoryWebApiModule.forRoot(
      InMemoryDataService, {
        dataEncapsulation: false,
        passThruUnknownUrl: true
      }
    ),
    AppModule,
    BrowserTransferStateModule
  ],
  providers: [ConfigService, PagerService, GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
