import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import { PagerService } from '../pager.service';
import { Post } from '../Post';
import { AuthenticationService } from '../authentication.service';
import { NgxMasonryOptions } from 'ngx-masonry';
import { SeoService } from '../seo.service';



@Component({
	selector: 'app-hr-aspire',
	templateUrl: './hr-aspire.component.html',
	styleUrls: ['./hr-aspire.component.css']
})
export class HrAspireComponent implements OnInit {

	aspire: any = {};

	allItems: any[];
	pages: any[];

	pager: any = {};
	pageSize = 9;
	posts: Post[];
	isloggedIn: boolean;
	updateLayout: boolean;
	postCount: number;

	constructor(private config: ConfigService, private pagerService: PagerService, private auth: AuthenticationService, private seo: SeoService) { }


	ngOnInit() {
		this.isloggedIn = this.auth.isloggedIn();
		this.aspire = this.getAspire();
		this.getPosts();
		this.updateLayout = true;
		this.postCount = 0;
		this.seo.setSocialMediaTags(
			  window.location.href, 
			  this.aspire.title +' | Blue Ramp Management',
			  this.aspire.subtitle,
			  'background1.jpg');


	}


	getBlog() {
		return this.config.getConfig().blog;
	}
	
	getAspire() {
		return this.config.getConfig().aspire;
	}

	getPosts() {
		this.config.getAspires().subscribe(
			posts => {
				this.posts = posts;
				this.allItems = this.posts;
				this.setPage(1);

			}
		);
	}


	setPage(pageNumber: number) {
		//create a pager using paging service
		this.pager = this.pagerService.getPager(this.allItems.length, pageNumber, this.pageSize);
		console.log(this.pager);
		//current page posts
		this.pages = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
		console.log(this.pages);
	}



}
